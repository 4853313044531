import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Box, Button, makeStyles, Theme } from "@material-ui/core"
import { toCategorySlug } from "../utils"

const useStyles = makeStyles((theme: Theme) => ({
    active: {
      color: theme.palette.primary.main,
      fontWeight: "bold"
    }
  })
)

export default function CategoriesNav() {
  const classes = useStyles();
  const { categories } = useStaticQuery(graphql`
    query {
      categories: allMarkdownRemark {
        group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `)

  return (
    <Box display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
      <Button component={Link} to="/work" activeClassName={classes.active}>
        All
      </Button>
      {categories.group.map(({ fieldValue }, i) => (
        <Button
          key={"bloglink-" + i}
          component={Link}
          to={toCategorySlug(fieldValue)}
          activeClassName={classes.active}
        >
          {fieldValue}
        </Button>
      ))}
    </Box>
  )
}
