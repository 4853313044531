import React from "react"
import { graphql } from "gatsby"
import BlogPage from "../components/blog-page"

export default function CategoriesList(props) {
  const { data, pathContext } = props
  const { items } = data

  return (
    <BlogPage
      title={pathContext.category}
      items={items.edges}
    />
  )
}

export const query = graphql`
  query categoryListQuery($category: String) {    
    items: allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $category } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            date
            video
            thumbnail {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`
