import React, { useEffect, createRef } from "react"
import Img, { FixedObject } from "gatsby-image"
import {
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "2rem",
      marginBottom: "15px",
    },
    paper: {
      overflow: "hidden",
      height: "550px",
      width: "100%",
      transition: ".5s",
      position: "relative",
      "&:hover": {
        zIndex: 99,
        transform: "scale(1.1)",
        boxShadow: theme.shadows[12],
      },
      "&:hover $overlay": {
        opacity: 1,
      },
    },
    video: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      zIndex: 100,
      objectFit: "cover",
    },
    overlay: {
      position: "absolute",
      opacity: 0,
      top: 0,
      width: "100%",
      height: "100%",
      transition: "1s",
    },
    meta: {
      zIndex: 101,
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      height: "100%",
      color: "#fff",
      background: "linear-gradient(to top, #000, transparent)",
    },
    metaInner: {
      padding: theme.spacing(3),
    },
    date: {
      marginBottom: theme.spacing(1),
    },
  })
)

export interface BlogListItemData {
  image: FixedObject
  alt: string
  videoUrl: string
  title: string
  excerpt: string
  date: string
  slug: string
  isActive?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function BlogListItemData(data: BlogListItemData) {
  const classes = useStyles()
  const options = { year: "numeric", month: "long", day: "numeric" }
  const date = new Date(data.date).toLocaleDateString([], options)
  const videoRef = createRef<any>()

  useEffect(() => {
    data.isActive ? videoRef.current.play() : videoRef.current.pause()
  })

  const onMouseEnter = () => {
    data.onMouseEnter()
  }

  const onMouseLeave = () => {
    data.onMouseLeave()
  }

  return (
    <Paper
      className={classes.paper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Img fixed={data.image} alt={data.alt} />
      <div className={classes.overlay}>
        <video
          muted
          preload="none"
          className={classes.video}
          key={data.videoUrl}
          ref={videoRef}
          src={data.videoUrl}
          loop={true}
        />
        <div className={classes.meta}>
          <div className={classes.metaInner}>
            <Typography variant="h2" className={classes.title}>
              {data.title}
            </Typography>
            <Typography variant="body2" className={classes.date}>
              {date}
            </Typography>
            <Typography variant="body1">{data.excerpt}</Typography>
          </div>
        </div>
      </div>
    </Paper>
  )
}
