import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import BlogListItem, { BlogListItemData } from "../components/blog-list-item"
import CategoriesNav from "./categories-nav"

export interface BlogPageProps {
  title: string
  body?: string
  items: any[]
}

export default function BlogPage(data: BlogPageProps) {
  const [activeItem, setActiveItem] = useState("")
  const { items } = data

  const blogItems: BlogListItemData[] = items.map(({ node }) => {
    const { frontmatter } = node
    const { slug } = node.fields
    const { thumbnail, thumbnailAlt, video, title, excerpt, date } = frontmatter
    const item: any = {}
    item.image = thumbnail.childImageSharp.fixed
    item.alt = thumbnailAlt
    item.videoUrl = video
    item.excerpt = excerpt
    item.title = title
    item.date = date
    item.slug = slug
    return item
  })

  return (
    <section style={{ paddingTop: "20px" }}>
      <Container maxWidth="md">
        <Box textAlign="center" mb={3}>
          <Box mb={2}>
            <Typography variant="h1">{data.title}</Typography>
          </Box>
          {data?.body && <Typography variant="body1">{data.body}</Typography>}
        </Box>
      </Container>

      <Container>
        <CategoriesNav />
      </Container>

      <Container>
        <Grid container spacing={2}>
          {blogItems.map((item, i) => (
            <Grid
              item
              md={4}
              key={"photoitem-" + i}
              style={{ maxWidth: "100%" }}
            >
              <Link to={item.slug}>
                <BlogListItem
                  {...item}
                  isActive={activeItem == item.slug}
                  onMouseEnter={() => setActiveItem(item.slug)}
                  onMouseLeave={() => setActiveItem("")}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  )
}
